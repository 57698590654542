<template>
  <div>
    <!-- <sidebarNav/> -->
    <mainComtent />

  </div>
</template>

<script>
import sidebarNav from "./components/sidebar-nav"
import mainComtent from "./components/main-content/index"
import mapState from "vuex"
export default {
components:{
  sidebarNav,
  mainComtent
}
}
</script>

<style>

</style>