<template>
  <div class="tags-view-container">
    <el-tabs
      v-model="isChangePath"
      type="card"
      @tab-click="clickTab"
      @tab-remove="removeTab"
    >
      <el-tab-pane
        v-for="item in Array.from(visitedViews)"
        :key="item.path"
        :label="item.meta.name"
        :name="item.name"
        :closable="item.path == '/home' ? false : true"
      >
      </el-tab-pane>
    </el-tabs>
    <!-- <span> ****{{visitedViews}}****</span> -->
  </div>
</template>
 
<script>
import { mapGetters } from "vuex";
export default {
  name: "Tags",
  data() {
    return {
      isChangePath: "",
    };
  },
  computed: {
    ...mapGetters(["visitedViews", "currentRouter"]),
  },
  created() {
    // console.log(this.$route.path);
    this.isChangePath = this.$route.name;
  },
  methods: {
    clickTab(tab) {
      if (this.$route.name) {
        this.$store.dispatch("changeVisitedViews", { name: tab.name });
        this.$router.push({ name: tab.name });
      }
    },
    removeTab(name) {
      // console.log(name);

      var route = { name: name };

      this.$store.dispatch("delVisitedViews", route).then((views) => {
        // 此时的views是指的被删除后的 visitedViews 数组中存在的元素,即resolve的回调
        if (this.isActive(route)) {
          //当前关闭的标签是否是被选中的标签
          /* slice() 方法可从已有的数组中返回选定的元素。
            如果是负数，那么它规定从数组尾部开始算起的位置,-1 指最后一个元素.*/
          let lastView = views.slice(-1)[0]; //选取路由数组中的最后一位
          if (lastView) {
            this.$router.push(lastView);
            // this.$store.dispatch('changeVisitedViews', lastView);//改变左侧菜单
          } else {
            console.log("tag err....");

            // this.$router.push(this.currentRouter[1].path);
            // this.$store.dispatch('changeVisitedViews', this.currentRouter[1]);//改变左侧菜单
          }
        }
      });
    },
    isActive(route) {
      //判断页签的路由是否为当前路由
      return route.name === this.$route.name;
    },
    viewTagChange(route) {
      // console.log(route,"999");

      if (this.$route.name) {
        this.$store.dispatch("changeVisitedViews", route);
      }
    },
    addViewTags() {
      //路由改变时执行的方法
      if (this.$route.name) {
        const route = this.$route;
        this.$store.dispatch("addVisitedViews", route);
      }
    },
  },
  watch: {
    $route(val) {
      //点击左侧菜单树，或者点击其他的tab页签，会导致路由的改变
      // console.log(val,"***");
      this.$store.dispatch("changeVisitedViews", val); //改变左侧菜单
      this.addViewTags();

      this.isChangePath = val.name;
    },
  },
};
</script>
 
<style scoped  lang="scss">
.tags-view-container {
  border-top: 1px solid #e4e7ed;
  padding-top: 15px;
  padding-left: 15px;
}

a {
  text-decoration: none;
  color: #000;
  margin-left: 5px;
}
a:first-of-type {
  margin-left: 0;
}
.el-tag--medium {
  height: 34px;
  line-height: 34px;
  padding: 0 20px;
  font-size: 14px;
}
// /deep/.el-tag.el-icon-close{
//   font-size: 18px;
// }
</style>