<template>

  <div class="menu">
      <!-- <el-menu
      background-color="#304456"
      text-color="#eee"
      active-text-color="#4dbcff"
      :default-active="currentMenu"
      :collapse="isCollapse"
      >
        <DynamicMenu :menuList="sidebarMenu"></DynamicMenu>
   


     </el-menu> -->

    <el-menu
      background-color="#242834"
      text-color="#fff"
      active-text-color="#4dbcff"
       :default-active="currentMenu"
      class="el-menu-vertical-demo"
      style="min-height: 100vh; overflow-x: hidden"
       unique-opened
      :collapse="isCollapse"
    >
      <div v-if="!isCollapse" class="title">管理后台</div>
      <div v-else class="title">
        <el-tooltip
          class="item"
          effect="dark"
          content="管理后台"
          placement="right-start"
        >
          <span class="el-icon-more-outline"></span>
        </el-tooltip>
      </div>

          <DynamicMenu :menuList="sidebarMenu"></DynamicMenu>
         
    </el-menu>
  
    <div class="el-scrollbar__bar is-horizontal">
      <div class="el-scrollbar__thumb" style="transform: translateX(0%)"></div>
    </div>
    <div class="el-scrollbar__bar is-vertical">
      <div class="el-scrollbar__thumb" style="transform: translateY(0%)"></div>
    </div>
  </div>
</template>

<script>
import DynamicMenu from "@/components/dynamic-menu";
import { mapState,mapGetters } from "vuex";
export default {
  data() {
    return {
     
    };
  },
  computed: {
    ...mapState("permission", ["sidebarMenu"]),
    ...mapState(["isCollapse","currentMenu"]),
    ...mapGetters(["currentRouter"]), 
  },
  mounted() {},
  methods: {
   
  },
  components: {
    DynamicMenu,
  },
};
</script>

<style scoped>
.menu {
  overflow-x: hidden;
  height: 100%;

  margin-bottom: -13px;
  margin-right: -13px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.menu::-webkit-scrollbar {
  /*滚动条基本样式，高度*/
  width: 4px;
  height: 4px;
}
.menu::-webkit-scrollbar-thumb {
  /*滚动条上层颜色*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.menu::-webkit-scrollbar-track {
  /*滚动条底层颜色*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.el-menu {
  border-right: none;
}
.menuitem .el-submenu__title {
  background: #2e2f34 !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 256px;
  min-height: 100vh;
  background-color: #242834 !important;
}

.title {
  color: white;

  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;

  font-size: 30px;
  line-height: 60px;
  font-weight: 800;
}
.title > span {
  font-size: 16px;
  color: #909399;
}
.img {
  width: 150px;
  height: 45px;
  display: block;
  margin: 5px auto 0 auto;
  cursor: pointer;
}
.el-scrollbar__bar {
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.12s ease-out;
}
.el-scrollbar__bar.is-horizontal {
  height: 6px;
  left: 2px;
}
.el-scrollbar__bar.is-vertical {
  width: 6px;
  top: 2px;
}
.el-scrollbar__bar {
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.12s ease-out;
}
.el-scrollbar__thumb {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(144, 147, 153, 0.3);
  transition: background-color 0.3s;
}
.el-scrollbar__bar.is-vertical > div {
  width: 100%;
}
.el-scrollbar__bar.is-horizontal > div {
  height: 100%;
}

* {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
    background 0s, color 0s, font-size 0s;
}
</style>