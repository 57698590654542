<template>
<div class="admin-wrapper" >
  <router-view ></router-view>
</div>

  
</template>

<script>

export default {
 
}
</script>

<style lang="scss" scoped>
.admin-wrapper{
    width: calc(100% - 80px);
    margin: 20px auto;
    background: #fff;
    border-radius: 2px;
    padding:20px;
}
   
</style>>
