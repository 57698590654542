<template>
  <div class="main-container" >
  <!-- :style="{marginLeft:(isCollapse?'65px':'256px') }" -->
      <TopAside/>
       <Content/>
  </div>
</template>

<script>
import TopAside from "./top-aside"
import Content from "./content"

import { mapState, mapMutations } from "vuex";
export default {
  components:{
      TopAside,
      Content
  },
   computed: {
    ...mapState(["isCollapse"]),
  },
}
</script>

<style scoped>
.main-container{
    position: relative;
    min-height: 100%;
    /* margin-left: 256px; */
    background: #f6f8f9;
    padding-top: 128px;
}

</style>