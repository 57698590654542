<template>
  <div class="fixed-header"  >
    <!-- :style="{left:(isCollapse?'65px':'256px'),width:(isCollapse?' calc(100% - 65px)':' calc(100% - 256px)') } " -->
    <el-header>
      <el-row class="flex-align-center flex">
        <el-col :span="20">
          <div class="breadcrumb flex flex-align-center">
            <div class="top_icon" @click="COLLAPSE">
              <span
                class="el-icon-s-fold fold"
                style="color: #666"
                v-if="isCollapse"
              ></span>
              <span class="el-icon-s-unfold fold" v-else></span>
            </div>
            <el-breadcrumb class="app-breadcrumb" separator=">">
              <!-- <transition-group> -->
              <el-breadcrumb-item v-for="item in levelList" :key="item.path">
                <!-- @click.prevent="handleLink(item)" -->
                <a> {{ item.meta.name }} </a>
              </el-breadcrumb-item>
              <!-- </transition-group> -->
            </el-breadcrumb>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="right flex flex-align-center justifyEnd">
            <div class="avatar">
              <img
                src="../../../../assets/headPortrait.gif"
                alt=""
              />
            </div>
            <div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{$store.state.UserToken.realname}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-header>
     <Tags class="tags" />
   
  </div>
</template>

<script>

import Tags from "@/components/tags";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      levelList: null,
    };
  },
  components: {
    Tags,
  },
  created() {
    this.getBreadList();
  },
  watch: {
    $route() {
      this.getBreadList();
    },
  },
  computed: {
    ...mapState(["isCollapse"]),
  },
  methods: {
    ...mapMutations(["COLLAPSE"]),
    handleCommand(e){
      console.log(e);
      
      if(e=="loginout"){
        
        this.$store.commit('LOGIN_OUT')
        this.$store.dispatch('delAllVisitedViews')
        this.$router.push("/login");
        //   console.log('清除',localStorage.getItem('token'));
        // //  this.$store.commit("DEL_All_VISITED_VIEWS")
       
     
       
        // history.pushState(null, null, document.URL);
        // window.addEventListener("popstate",function(e) {  
        //     history.pushState(null, null, document.URL);
        // }, false);

      }
      
      
    },
    getBreadList() {
      let matched = this.$route.matched.filter((item) => item.name);

      this.levelList = [];
      this.levelList = matched;
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      // this.$router.push(this.pathCompile(path))
    },
    //  pathCompile(path) {
    //   const { params } = this.$route
    //   var toPath = pathToRegexp.compile(path)//url 的正则表达式,快速填充 url 字符串的参数值。
    //   return toPath(params)
    // },
  },
};
</script>
<style scoped  lang="scss">
.fixed-header {
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 997;
  width: 100%;
  overflow: hidden;
  // left: 256px;
  // width: calc(100% - 256px);
}
.top_icon {
  margin-right: 15px;
}
.right {
  height: 60px;
  .avatar {
    margin-right: 18px;

    img {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    font-size: 16px;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
}
.fold {
  font-size: 26px;
  cursor: pointer;
  line-height: 40px;
}
</style>